@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base {
  .blur-3xl {
    --tw-blur: blur(64px);
    filter: var(--tw-blur);
  }
}

.backdrop-blur-sm {
  backdrop-filter: blur(8px);
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

@layer utilities {
  .bg-noise {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 256 256' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  }
}

/* Optional: Add this for smoother animations */
* {
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

.border-gradient {
  border-width: 1px;
  border-image: linear-gradient(to right, rgb(16 185 129), rgb(236 72 153)) 1;
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes rainbow-spin {
  0% {
    transform: rotate(0deg);
    border-color: #10b981;
    box-shadow: 0 0 20px #10b981;
  }
  25% {
    border-color: #8b5cf6;
    box-shadow: 0 0 20px #8b5cf6;
  }
  50% {
    border-color: #ec4899;
    box-shadow: 0 0 20px #ec4899;
  }
  75% {
    border-color: #3b82f6;
    box-shadow: 0 0 20px #3b82f6;
  }
  100% {
    transform: rotate(360deg);
    border-color: #10b981;
    box-shadow: 0 0 20px #10b981;
  }
}

@keyframes float-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: float-up 1s ease-out forwards;
}

.particle {
  position: absolute;
  width: 4px;
  height: 4px;
  background: rgba(16, 185, 129, 0.2);
  border-radius: 50%;
  left: var(--particle-x);
  bottom: -10px;
  animation: particle-float var(--particle-speed) ease-in infinite;
  animation-delay: var(--particle-delay);
}

@keyframes particle-float {
  0% {
    transform: translateY(0) scale(1);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(-100vh) scale(0);
    opacity: 0;
  }
}

.particles-container {
  position: absolute;
  inset: 0;
  overflow: hidden;
  z-index: -1;
}

/* Weather particle animations */
.particle-rain {
  position: absolute;
  width: 1px;
  height: 20px;
  background: linear-gradient(transparent, rgba(255, 255, 255, 0.5));
  left: var(--x);
  top: -20px;
  animation: rain-fall var(--duration) linear infinite;
  animation-delay: var(--delay);
}

.particle-snow {
  position: absolute;
  width: 4px;
  height: 4px;
  background: white;
  border-radius: 50%;
  left: var(--x);
  top: -4px;
  opacity: 0.8;
  animation: snow-fall calc(var(--duration) * 3) linear infinite;
  animation-delay: var(--delay);
}

.particle-fog {
  position: absolute;
  width: 500px;
  height: 300px;
  top: 40px;
  background: rgba(255, 255, 255, 0.1);
  left: var(--x);
  filter: blur(8px);
  border-radius: 50%;
  animation: fog-move calc(var(--duration) * 30) ease-in-out infinite;
  /* animation-delay: var(--delay); */
}

@keyframes rain-fall {
  0% {
    transform: translateY(-20px) skew(-20deg);
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) skew(-20deg);
    opacity: 0;
  }
}

@keyframes snow-fall {
  0% {
    transform: translateY(-4px) rotate(0deg);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
    opacity: 0;
  }
}

@keyframes fog-move {
  0% {
    transform: translateX(-100%) translateY(0);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(100%) translateY(-20px);
    opacity: 0;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  border: 2px solid transparent;
  background-clip: content-box;
  backdrop-filter: blur(4px);
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
}
/* For mobile viewport height fix */
@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

.animate-bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

